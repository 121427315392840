import React from 'react'
import data from '../data/generic'
import dataPetersburg from '../data/saint-petersburg'
import dataPrague from '../data/prague'
import dataTbilisi from '../data/tbilisi'
import dataMoscow from '../data/moscow'
import IndexPage from '../components'
import Section from '../components/Section'
import { graphql } from 'gatsby'
import Badges from '../components/Badges'
import Phone from '../components/Phone'
import Screencast from '../components/Screencast'
import appStoreScreenshot from '../images/screenshots/09.jpg'

export default ({ data: { image } }) => {
  return (
    <IndexPage
      gatsbyCover={image.childImageSharp}
      action={
        <div class="NanoMarged--xs">
          <Badges className="center-xs start-md" />
        </div>
      }
      heading="Экскурсии с мобильным гидом"
      lead={
        <span>
          Превратите ваш телефон в&nbsp;личного гида, который проведет экскурсию
          по городу
        </span>
      }
      title="Мобильный гид — скачать приложение Surprise Me"
      description="Приложение превратит ваш телефон в&nbspличного гида, который проведет экскурсию по городу. Проходите экскурсии самостоятельно с Surprise Me."
      {...data}
      reviews={[
        dataTbilisi.reviews[0],
        dataMoscow.reviews[0],
        ...dataPetersburg.reviews,
        dataPrague.reviews[0],
      ]}
    >
      <Section className="white-section">
        <div className="row middle-sm between-sm start-md center-xs TopNanoMarged--xs">
          <div className="col-xs-12 col-sm-7 Marged">
            <h2>Скачайте бесплатное приложение Surprise Me</h2>
            <p className="lead">
              Выбирайте интересную экскурсию и начинайте ее в любое время
            </p>
            <Badges />
            {/* <div className="row NanoMarged" style={{ opacity: '0.5' }}>
                  <div className="col-xs-6">
                    <h3>
                      4,9 ⭐️ <br />
                      App Store
                    </h3>
                  </div>
                  <div className="col-xs-6">
                    <h3>
                      4,2 ⭐️ <br />
                      Google Play
                    </h3>
                  </div>
                </div> */}
          </div>
          <div className="col-xs-12 col-sm-5 self-bottom phone-col phone-col--right">
            <Phone className="phone--white-notch-bg" theme="dark">
              <Screencast poster={appStoreScreenshot} />
            </Phone>
          </div>
        </div>
      </Section>
    </IndexPage>
  )
}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "covers/paris.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 70) {
          ...GatsbyImageSharpFluid_noBase64
        }
        sqip(numberOfPrimitives: 12, blur: 12, width: 256, height: 256) {
          dataURI
        }
      }
    }
  }
`
