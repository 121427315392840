export default () => {
  if(navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPad/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i)
  ){
     return true;
   }
  else {
     return false;
   }
}



export const isIOS = () => {
  try {
    return [
      navigator.userAgent.match(/iPhone/i),
      navigator.userAgent.match(/iPad/i),
      navigator.userAgent.match(/iPod/i),
    ].some(Boolean);
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const isAndroid = () => {
  try {
    return Boolean(navigator.userAgent.match(/Android/i));
  } catch (e) {
    console.log(e);
    return false;
  }
};