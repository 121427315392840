import React from 'react'
import appLinks from '../../constants/applinks'
import appStoreBadge from '../../images/badges/app-store-badge-ru.svg'
import googlePlayBadge from '../../images/badges/google-play-badge-ru.svg'
import { isIOS, isAndroid } from '../../utils/isMobile'
import Details from '../details'
import cn from '../../utils/classnames'
import './badge.css'

export default class Badges extends React.Component {
  state = {
    isAndroidFlag: isAndroid(),
    isIOSFlag: isIOS(),
    isDesktop: !isIOS() && !isAndroid(),
    hasMounted: false,
  }

  componentDidMount() {
    this.setState({
      isAndroidFlag: isAndroid(),
      isIOSFlag: isIOS(),
      isDesktop: !isIOS() && !isAndroid(),
      hasMounted: true,
    })
  }

  render() {
    const { theme = 'black', className } = this.props
    const { isAndroidFlag, isIOSFlag, isDesktop, hasMounted } = this.state

    const appStoreBadgeLink = (
      <a
        className="badge PicoMarged--xs"
        href={appLinks.ios}
        data-eventcategory="app"
        data-eventaction="click-download"
        data-eventlabel="click-app-download"
      >
        <img src={appStoreBadge} />
      </a>
    )

    const googlePlayBadgeLink = (
      <a
        className="badge PicoMarged--xs"
        href={appLinks.android}
        data-eventcategory="app"
        data-eventaction="click-download"
        data-eventlabel="click-app-download"
      >
        <img src={googlePlayBadge} />
      </a>
    )

    return (
      <div className={cn('row', className)}>
        <div className={cn('col-xs-12 col-sm with-no-grow')}>
          {isIOSFlag || isDesktop ? (
            appStoreBadgeLink
          ) : (
            <Details title="У меня iOS" linkClass={`${theme}-pseudo`}>
              {appStoreBadgeLink}
            </Details>
          )}
        </div>
        <div
          className={cn('col-xs-12 col-sm with-no-grow')}
          style={{ order: isAndroidFlag ? '-1' : '1' }}
          key={hasMounted.toString() + isAndroidFlag.toString()}
        >
          {isAndroidFlag || isDesktop ? (
            googlePlayBadgeLink
          ) : (
            <Details title="У меня Android" linkClass={`${theme}-pseudo`}>
              {googlePlayBadgeLink}
            </Details>
          )}
        </div>
      </div>
    )
  }
}
