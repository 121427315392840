import generic from './generic'
import galya from '../images/reviews/Galya.png'
import cover from '../images/covers/moscow.jpg'

export default {
  ...generic,
  slug: 'moscow',
  cover,
  collections: [
    'novinki',
    'unusual',
    'review',
    'children',
    'about-history-and-architecture',
    'bars-and-nightlife',
  ],
  cityNames: ['Москва', 'Москве'],
  reviews: [
    {
      avatar: 'https://app.surprizeme.ru/media/users/2228/photo.jpg',
      name: 'Наталья',
      text:
        'Прошли с вашими экскурсиями пол Петербурга, Прагу, вот и до Москвы добрались. Все очень логично и по маршруту, и по вопросам',
      product: {
        name: 'Москва масонская: необычная прогулка по Бульварному кольцу',
        link:
          'https://surprizeme.ru/ru/moscow/unusual/moskva-masonskaya-neobychnaya-progulka-po-bulvarnomu-kolcu/',
      },
    },
    {
      avatar: galya,
      name: 'Галя',
      text:
        'На пятой локации экскурсии замешкалась, а так все замечательно)) спасибо!',
      product: {
        name: 'Москва масонская: необычная прогулка по Бульварному кольцу',
        link:
          'https://surprizeme.ru/ru/moscow/unusual/moskva-masonskaya-neobychnaya-progulka-po-bulvarnomu-kolcu/',
      },
    },
  ],
}
